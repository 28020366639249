(function (b, f) {
    "object" === typeof exports && "undefined" !== typeof module ? f(exports) : "function" === typeof define && define.amd ? define(["exports"], f) : f(b.dlgDolphin = {})
})(this, function (b) {
    function f(a, c) {
        return c = {
            exports: {}
        },
            a(c, c.exports),
            c.exports
    }
    let trackingObject = function ()
        {
            function a() {
            }
            a.unavailableMsg = function (a) {
                return "No " + a + " available"
            };
            a.isDataAttr = function (a, b) {
                return b ? b.getAttribute("data-analytics-" + a) ? !0 : !1 : document.querySelector("[data-analytics-" + a + "]") ? !0 : !1
            };
            a.getDataAttr = function (a, b) {
                const c = ("analytics-" + a).replace(/-([a-z])/g, function (a) {
                    return a[1].toUpperCase()
                });
                return b ? b.dataset[c] : document.querySelector("[data-analytics-" + a + "]").dataset[c]
            };
            a.getPageName = function () {
                let a;
                this.isDataAttr("page-name") ? a = this.getDataAttr("page-name") : (a = 0 < window.location.hash.length ? window.location.hash : window.location.pathname.substr(1).replace(/\/$/, "").replace(/-/g, " ").replace(/\//g, " - "),
                    a = a.replace(/\w\S*/g, function (a) {
                        return a.charAt(0).toUpperCase() + a.substr(1).toLowerCase()
                    }));
                return a
            };
            a.getProduct = function () {
                let a;
                this.isDataAttr("product") ? a = this.getDataAttr("product") : (a = 0 < window.location.hash.length ? window.location.hash : window.location.pathname.substr(1).replace(/\/$/, "").replace(/-/g, " ").replace(/\//g, " - "),
                    a = a.replace(/\w\S*/g, function (a) {
                        return a.charAt(0).toUpperCase() + a.substr(1).toLowerCase()
                    }));
                return a
            };
            a.getClosestInteractiveElement = function (a) {
                for (; null !== a;) {
                    if ("A" === a.tagName.toUpperCase() || "BUTTON" === a.tagName.toUpperCase())
                        return a;
                    a = a.parentElement
                }
                return null
            };
            a.getVisitTime = function () {
                let a = JSON.parse(window.sessionStorage.getItem("dlg_data_visittime"));
                a || (a = this.getEventTime(), window.sessionStorage.setItem("dlg_data_visittime", JSON.stringify(a)));
                return a
            };
            a.getEventTime = function () {
                return (new Date).toLocaleString("en-GB").replace(",", "")
            };
            a.hashProp = function(a) {
                return hashString(a).toString()
            };
            return a
        }(),
        kc = f(function(a, c) {
            (function(c, b) {
                    a.exports = b()
                }
            )(window, function() {
                var a = a || function(a, c) {
                    var b = Object.create || function() {
                            function a() {}
                            return function(c) {
                                a.prototype = c;
                                c = new a;
                                a.prototype = null;
                                return c
                            }
                        }(),
                        d = {},
                        e = d.lib = {},
                        f = e.Base = function() {
                            return {
                                extend: function(a) {
                                    let c = b(this);
                                    a && c.mixIn(a);
                                    c.hasOwnProperty("init") && this.init !== c.init || (c.init = function() {
                                            c.$super.init.apply(this, arguments)
                                        }
                                    );
                                    c.init.prototype = c;
                                    c.$super = this;
                                    return c
                                },
                                create: function() {
                                    var a = this.extend();
                                    a.init.apply(a, arguments);
                                    return a
                                },
                                init: function() {},
                                mixIn: function(a) {
                                    for (var c in a)
                                        a.hasOwnProperty(c) && (this[c] = a[c]);
                                    a.hasOwnProperty("toString") && (this.toString = a.toString)
                                },
                                clone: function() {
                                    return this.init.prototype.extend(this)
                                }
                            }
                        }()
                        , g = e.WordArray = f.extend({
                            init: function(a, b) {
                                a = this.words = a || [];
                                this.sigBytes = b != c ? b : 4 * a.length
                            },
                            toString: function(a) {
                                return (a || m).stringify(this)
                            },
                            concat: function(a) {
                                var c = this.words
                                    , b = a.words
                                    , d = this.sigBytes;
                                a = a.sigBytes;
                                this.clamp();
                                if (d % 4)
                                    for (var e = 0; e < a; e++)
                                        c[d + e >>> 2] |= (b[e >>> 2] >>> 24 - e % 4 * 8 & 255) << 24 - (d + e) % 4 * 8;
                                else
                                    for (e = 0; e < a; e += 4)
                                        c[d + e >>> 2] = b[e >>> 2];
                                this.sigBytes += a;
                                return this
                            },
                            clamp: function() {
                                var c = this.words
                                    , b = this.sigBytes;
                                c[b >>> 2] &= 4294967295 << 32 - b % 4 * 8;
                                c.length = a.ceil(b / 4)
                            },
                            clone: function() {
                                var a = f.clone.call(this);
                                a.words = this.words.slice(0);
                                return a
                            },
                            random: function(c) {
                                for (var b = [], d = function(c) {
                                    var b = 987654321;
                                    return function() {
                                        b = 36969 * (b & 65535) + (b >> 16) & 4294967295;
                                        c = 18E3 * (c & 65535) + (c >> 16) & 4294967295;
                                        return (((b << 16) + c & 4294967295) / 4294967296 + .5) * (.5 < a.random() ? 1 : -1)
                                    }
                                }, e = 0, f; e < c; e += 4) {
                                    var k = d(4294967296 * (f || a.random()));
                                    f = 987654071 * k();
                                    b.push(4294967296 * k() | 0)
                                }
                                return new g.init(b,c)
                            }
                        })
                        , k = d.enc = {}
                        , m = k.Hex = {
                            stringify: function(a) {
                                var c = a.words;
                                a = a.sigBytes;
                                for (var b = [], d = 0; d < a; d++) {
                                    var e = c[d >>> 2] >>> 24 - d % 4 * 8 & 255;
                                    b.push((e >>> 4).toString(16));
                                    b.push((e & 15).toString(16))
                                }
                                return b.join("")
                            },
                            parse: function(a) {
                                for (var c = a.length, b = [], d = 0; d < c; d += 2)
                                    b[d >>> 3] |= parseInt(a.substr(d, 2), 16) << 24 - d % 8 * 4;
                                return new g.init(b,c / 2)
                            }
                        }
                        , w = k.Latin1 = {
                            stringify: function(a) {
                                var c = a.words;
                                a = a.sigBytes;
                                for (var b = [], d = 0; d < a; d++)
                                    b.push(String.fromCharCode(c[d >>> 2] >>> 24 - d % 4 * 8 & 255));
                                return b.join("")
                            },
                            parse: function(a) {
                                for (var c = a.length, b = [], d = 0; d < c; d++)
                                    b[d >>> 2] |= (a.charCodeAt(d) & 255) << 24 - d % 4 * 8;
                                return new g.init(b,c)
                            }
                        }
                        , l = k.Utf8 = {
                            stringify: function(a) {
                                try {
                                    return decodeURIComponent(escape(w.stringify(a)))
                                } catch (qc) {
                                    throw Error("Malformed UTF-8 data");
                                }
                            },
                            parse: function(a) {
                                return w.parse(unescape(encodeURIComponent(a)))
                            }
                        }
                        , h = e.BufferedBlockAlgorithm = f.extend({
                            reset: function() {
                                this._data = new g.init;
                                this._nDataBytes = 0
                            },
                            _append: function(a) {
                                "string" == typeof a && (a = l.parse(a));
                                this._data.concat(a);
                                this._nDataBytes += a.sigBytes
                            },
                            _process: function(c) {
                                var b = this._data
                                    , d = b.words
                                    , e = b.sigBytes
                                    , f = this.blockSize
                                    , k = e / (4 * f);
                                k = c ? a.ceil(k) : a.max((k | 0) - this._minBufferSize, 0);
                                c = k * f;
                                e = a.min(4 * c, e);
                                if (c) {
                                    for (var m = 0; m < c; m += f)
                                        this._doProcessBlock(d, m);
                                    m = d.splice(0, c);
                                    b.sigBytes -= e
                                }
                                return new g.init(m,e)
                            },
                            clone: function() {
                                var a = f.clone.call(this);
                                a._data = this._data.clone();
                                return a
                            },
                            _minBufferSize: 0
                        });
                    e.Hasher = h.extend({
                        cfg: f.extend(),
                        init: function(a) {
                            this.cfg = this.cfg.extend(a);
                            this.reset()
                        },
                        reset: function() {
                            h.reset.call(this);
                            this._doReset()
                        },
                        update: function(a) {
                            this._append(a);
                            this._process();
                            return this
                        },
                        finalize: function(a) {
                            a && this._append(a);
                            return this._doFinalize()
                        },
                        blockSize: 16,
                        _createHelper: function(a) {
                            return function(c, b) {
                                return (new a.init(b)).finalize(c)
                            }
                        },
                        _createHmacHelper: function(a) {
                            return function(c, b) {
                                return (new p.HMAC.init(a,b)).finalize(c)
                            }
                        }
                    });
                    var p = d.algo = {};
                    return d
                }(Math);
                return a
            })
        }),
        hashString = f(function(a, c) {
            (function(c, b) {
                    a.exports = b(kc)
                }
            )(window, function(a) {
                (function(c) {
                        var b = a.lib
                            , d = b.WordArray
                            , e = b.Hasher;
                        b = a.algo;
                        var f = []
                            , g = [];
                        (function() {
                                function a(a) {
                                    for (var b = c.sqrt(a), d = 2; d <= b; d++)
                                        if (!(a % d))
                                            return !1;
                                    return !0
                                }
                                function b(a) {
                                    return 4294967296 * (a - (a | 0)) | 0
                                }
                                for (var d = 2, e = 0; 64 > e; )
                                    a(d) && (8 > e && (f[e] = b(c.pow(d, .5))),
                                        g[e] = b(c.pow(d, 1 / 3)),
                                        e++),
                                        d++
                            }
                        )();
                        var k = [];
                        b = b.SHA256 = e.extend({
                            _doReset: function() {
                                this._hash = new d.init(f.slice(0))
                            },
                            _doProcessBlock: function(a, c) {
                                for (var b = this._hash.words, d = b[0], e = b[1], f = b[2], m = b[3], h = b[4], l = b[5], q = b[6], p = b[7], n = 0; 64 > n; n++) {
                                    if (16 > n)
                                        k[n] = a[c + n] | 0;
                                    else {
                                        var r = k[n - 15]
                                            , t = k[n - 2];
                                        k[n] = ((r << 25 | r >>> 7) ^ (r << 14 | r >>> 18) ^ r >>> 3) + k[n - 7] + ((t << 15 | t >>> 17) ^ (t << 13 | t >>> 19) ^ t >>> 10) + k[n - 16]
                                    }
                                    r = p + ((h << 26 | h >>> 6) ^ (h << 21 | h >>> 11) ^ (h << 7 | h >>> 25)) + (h & l ^ ~h & q) + g[n] + k[n];
                                    t = ((d << 30 | d >>> 2) ^ (d << 19 | d >>> 13) ^ (d << 10 | d >>> 22)) + (d & e ^ d & f ^ e & f);
                                    p = q;
                                    q = l;
                                    l = h;
                                    h = m + r | 0;
                                    m = f;
                                    f = e;
                                    e = d;
                                    d = r + t | 0
                                }
                                b[0] = b[0] + d | 0;
                                b[1] = b[1] + e | 0;
                                b[2] = b[2] + f | 0;
                                b[3] = b[3] + m | 0;
                                b[4] = b[4] + h | 0;
                                b[5] = b[5] + l | 0;
                                b[6] = b[6] + q | 0;
                                b[7] = b[7] + p | 0
                            },
                            _doFinalize: function() {
                                var a = this._data
                                    , b = a.words
                                    , d = 8 * this._nDataBytes
                                    , e = 8 * a.sigBytes;
                                b[e >>> 5] |= 128 << 24 - e % 32;
                                b[(e + 64 >>> 9 << 4) + 14] = c.floor(d / 4294967296);
                                b[(e + 64 >>> 9 << 4) + 15] = d;
                                a.sigBytes = 4 * b.length;
                                this._process();
                                return this._hash
                            },
                            clone: function() {
                                var a = e.clone.call(this);
                                a._hash = this._hash.clone();
                                return a
                            }
                        });
                        a.SHA256 = e._createHelper(b);
                        a.HmacSHA256 = e._createHmacHelper(b)
                    }
                )(Math);
                return a.SHA256
            })
        }),
        actionTracker = function () {
            function a(a) {
                void 0 === a && (a = !0);
                "mpa" === window.dlg_dolphin.architecture.toLowerCase() && this.getActionFromLocalStorage();
                this.trackLoad();
                a && this.attachListeners()
            }

            a.prototype.getActionFromLocalStorage = function () {
                const a = JSON.parse(window.sessionStorage.getItem("dlg_action"));
                window.dlg_data.action = a ? a : []
            };
            a.prototype.setActionInLocalStorage = function () {
                window.sessionStorage.setItem("dlg_action", JSON.stringify(window.dlg_data.action))
            };
            a.prototype.getAttributesFromLocalStorage = function () {
                const a = JSON.parse(window.sessionStorage.getItem("dlg_action_attributes"))
                    , b = window.sessionStorage.getItem("dlg_action_last_field");
                window.sessionStorage.removeItem("dlg_action_attributes");
                window.sessionStorage.removeItem("dlg_action_last_field");
                a && b && (a.last_field_interacted = b);
                return a
            };
            a.prototype.setLocalStorage = function (a) {
                window.sessionStorage.setItem("dlg_action_attributes", JSON.stringify(a))
            };
            a.prototype.trackLoad = function () {
                const a = {
                    event_name: this.formatEventName("Load", trackingObject.getPageName()),
                    event_time: trackingObject.getEventTime()
                };
                this.track(a)
            };
            a.prototype.trackModal = function (a) {
                a = {
                    event_name: this.formatEventName("Modal", a),
                    event_time: trackingObject.getEventTime()
                };
                this.track(a)
            };
            a.prototype.trackLink = function (a) {
                a = {
                    event_name: this.formatEventName("Link", this.getLinkName(a)),
                    event_time: trackingObject.getEventTime(),
                    attributes: {
                        component_name: this.getComponentName(a),
                        link_name: this.getLinkName(a)
                    }
                };
                this.track(a)
            };
            a.prototype.getComponentName = function (a) {
                const c = trackingObject.unavailableMsg("component name");
                if ("mpa" === window.dlg_dolphin.architecture.toLowerCase())
                    for (; !(trackingObject.isDataAttr("component-name", a) || (a = a.parentElement) && "BODY" === a.tagName.toUpperCase());)
                        ;
                return trackingObject.isDataAttr("component-name", a) ? trackingObject.getDataAttr("component-name", a) : c
            };
            a.prototype.getLinkName = function (a) {
                let c = trackingObject.unavailableMsg("link name");
                a.textContent && (c = a.textContent.trim());
                trackingObject.isDataAttr("link-name", a) && (c = trackingObject.getDataAttr("link-name", a));
                return c
            };
            a.prototype.formatEventName = function (a, b) {
                return a + ":" + b
            };
            a.prototype.track = function (a) {
                window.dlg_data.action.push(a);
                "mpa" === window.dlg_dolphin.architecture.toLowerCase() && this.setActionInLocalStorage()
            };
            a.prototype.attachListeners = function () {
                let a = this;
                document.addEventListener("focusin", function () {
                    let a = document.activeElement, b = a.tagName.toLowerCase();
                    -1 < ["input", "select", "radio", "checkbox", "button"].indexOf(b) && (a = a.id.match(/[^-]*/i)[0].replace(/([a-z])([A-Z])/g, "$1 $2").replace(/-/, " ").replace(/_/, " "),
                        a = a.split(" ").map(function (a) {
                            return a.charAt(0).toUpperCase() + a.slice(1)
                        }).join(" "),
                    0 <= window.dlg_data.action.length && (b = window.dlg_data.action.length - 1,
                    window.dlg_data.action[b].attributes && (window.dlg_data.action[b].attributes.last_field_interacted = trackingObject.getPageName() + " | " + a)))
                }, !0);
                document.addEventListener("click", function (b) {
                    b.stopPropagation();
                    (b = trackingObject.getClosestInteractiveElement(b.target)) && a.trackLink(b)
                }, !1)
            };
            return a
        }(),
        Ha = function () {
            Ha = function (a) {
                let b, d = 1;
                const e = arguments.length;
                for (; d < e; d++) {
                    b = arguments[d];
                    for (let f in b)
                        Object.prototype.hasOwnProperty.call(b, f) && (a[f] = b[f])
                }
                return a
            };
            return Ha.apply(this, arguments)
        },
        assetTracker = function () {
            function a() {
                window.dlg_data.asset = {
                    bike: []
                }
            }
            a.prototype.checkAssetBikeData = function (a) {
                const b = {};
                let c;
                for (c in a)
                    b[c] = a[c] ? a[c] : "";
                return b
            };
            a.prototype.trackAssetBike = function (a) {
                a && window.dlg_data.asset.bike.push(this.checkAssetBikeData(a))
            };
            a.prototype.removeTrackedAssetBike = function (a) {
                0 < window.dlg_data.asset.bike.length && (window.dlg_data.asset.bike = window.dlg_data.asset.bike.slice(0, a).concat(window.dlg_data.asset.bike.slice(a + 1)))
            };
            return a
        }(),
        errorFunction = function () {
            function a() {
            }
            a.prototype.trackError = function (a, b) {
                window.dlg_data.error = "string" === typeof a || a instanceof String ? {
                    error_message: a,
                    error_type: b
                } : a
            };
            return a
        }(),
        quoteTracker = function () {
            function a() {
            }
            a.prototype.trackQuote = function (a) {
                a && (window.dlg_data.quote = a)
            };
            return a
        }(),
        pageTracker = function () {
            function a() {
                window.dlg_data.page = {
                    brand: this.getBrandName(),
                    name: trackingObject.getPageName(),
                    product: trackingObject.getProduct(),
                    type: this.getPageType(),
                    query_param: this.getQueryParams(),
                    url: this.getUrl(),
                    opt_out: this.getDoNotTrack(),
                    visit_time: trackingObject.getVisitTime()
                }
            }
            a.prototype.getDoNotTrack = function () {
                let a = !1;
                if (window.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack)
                    if ("1" === window.doNotTrack || "yes" === navigator.doNotTrack || "1" === navigator.doNotTrack || "1" === navigator.msDoNotTrack)
                        a = !0;
                return a
            };
            a.prototype.getBrandName = function () {
                return window.dlg_dolphin ? window.dlg_dolphin.brand : ""
            };
            a.prototype.getPageType = function () {
                return window.dlg_dolphin ? window.dlg_dolphin.type : ""
            };
            a.prototype.getQueryParams = function () {
                return window.location.search ? window.location.search.substr(1) : ""
            };
            a.prototype.getUrl = function () {
                return window.location.href
            };
            return a
        }(),
        userTracker = function () {
            function a() {
            }
            a.prototype.checkProfileData = function (a) {
                const b = {};
                let c;
                for (c in a)
                    b[c] = a[c] ? a[c] : "";
                return b
            };
            a.prototype.trackUser = function (a) {
                a && (a.email && (a.email = trackingObject.hashProp(a.email)),
                a.DoB && (a.DoB = trackingObject.hashProp(a.DoB)),
                    window.dlg_data.user.push({
                        profile: this.checkProfileData(a)
                    }))
            };
            a.prototype.removeTrackedUser = function (a) {
                const b = window.dlg_data.user;
                window.dlg_data.user = b.slice(0, a).concat(b.slice(a + 1))
            };
            return a
        }(),
        tracker = function () {
            function a() {
                this.architecture = "No architecture available";
                this.brand = "No brand name available";
                this.firstLoad = !0;
                this.type = "No type available";
                this.version = "2.1.4";
                window.dlg_dolphin && (window.dlg_dolphin.brand && (this.brand = window.dlg_dolphin.brand),
                window.dlg_dolphin.type && (this.type = window.dlg_dolphin.type),
                window.dlg_dolphin.architecture && (this.architecture = window.dlg_dolphin.architecture));
                window.dlg_data = {
                    action: [],
                    user: [],
                    policy: {},
                    asset: {},
                    quote: {},
                    traffic_source: {},
                    error: {}
                }
            }
            a.prototype.trackPage = function () {
                this.page = new pageTracker;
                this.firstLoad ? (this.error = new errorFunction,
                    this.quote = new quoteTracker,
                    this.user = new userTracker) : (this.error || (this.error = new errorFunction),
                this.quote || (this.quote = new quoteTracker),
                this.user || (this.user = new userTracker));
                this.firstLoad = !1
            };
            return a
        }(),
        trackerLoader = function () {
            return function () {
                window.dlg_dolphin = new tracker
            }
        }();
    new trackerLoader;
    b.Init = trackerLoader;
});

if (window.dlg_dolphin) {
    window.dlg_dolphin.brand = 'DL';
    window.dlg_dolphin.type = 'Cycling'
    window.dlg_dolphin.architecture =  'MPA';
}
